import React from 'react'
import Helmet from 'react-helmet'

// import { Link } from 'gatsby'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'

import location from '../../img/location-pin.svg'
import phone from '../../img/phone-icon.svg'
import email from '../../img/email-icon.svg'

import facebook from '../../img/social/facebook.svg'
import linkedin from '../../img/social/linkedin.svg'
import twitter from '../../img/social/twitter.svg'


function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Contact Us | Advance Auto Movers</title>
          <meta 
            name="description" 
            content="Real auto transport professionals ready to help. We’d love to hear from you. Contact Us, Advance Auto Movers."
          />
        </Helmet>
        <section className="section">
          <div className="container">
            <div className="content">

            <h1>Contact Us</h1>
            <p>
              <em>
                Real auto transport professionals ready to help. We’d love to hear from you. Contact Us, Advance Auto Movers.
              </em>
              <br/>
              Our company values your feedback, which allows us to keep improving.   
            </p>

            <div className="columns">

              <div className="column is-4">
                <div style={{background: 'rgba(0,0,0,0.6)'}}>
                  <h2>Contact Information</h2>
                  <address>
                    <ul className="menu-list">
                      <li >
                        <span className="navbar-item">
                          <span className="icon">
                            <img src={location} alt="Advance Auto Movers LLC company address" />
                          </span>
                          <a href='https://maps.google.com/?q=Advance+Auto+Movers'>
                            <a href='https://maps.apple.com/maps?q=Advance+Auto+Movers'>
                              Advance Auto Movers
                              <br/>
                              2420 Hopewell Plantation Dr, Alpharetta, GA 30004
                            </a>
                          </a>
                        </span>
                      </li>
                      <li>
                        <span className="navbar-item">
                          <span className="icon">
                            <img src={phone} alt="Call Us" />
                          </span>
                            <a
                              href="tel:678-435-9348"
                            >
                              (678) 435-9348
                            </a>
                        </span>
                      </li>
                      <li>
                        <span className="navbar-item">
                          <span className="icon">
                            <img src={email} alt="Email Us" />
                          </span>
                            <a
                              href="mailto:advanceautomovers@gmail.com"
                            >
                              advanceautomovers@gmail.com
                            </a>
                        </span>
                      </li>
                    </ul>
                  </address>
                </div>
                <div>
                  <table style={{padding:'3px'}}>
                    <thead>
                      
                        <th colspan="2" style={{borderBottom: '1px solid #333', fontSize: '21px'}}>Open Hours</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Monday</td>
                        <td>7AM - 9PM</td>
                      </tr>
                      <tr>
                        <td>Tuesday</td>
                        <td>7AM - 9PM</td>
                      </tr>
                      <tr>
                        <td>Wednesday</td>
                        <td>7AM - 9PM</td>
                      </tr>
                      <tr>
                        <td>Thursday</td>
                        <td>7AM - 9PM</td>
                      </tr>
                      <tr>
                        <td>Friday</td>
                        <td>7AM - 9PM</td>
                      </tr>
                      <tr>
                        <td>Saturday</td>
                        <td>7AM - 9PM</td>
                      </tr>
                      <tr>
                        <td>Sunday</td>
                        <td>9AM - 5PM</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr/>
                <div>
                  <h3>Get connected on social media</h3>
                  <div className="social">
                    <a title="facebook" href="https://facebook.com/advanceautomovers">
                      <img
                        src={facebook}
                        alt="Facebook"
                        style={{ width: '1em', height: '1em' }}
                      />
                    </a>
                    <a title="linkedin" href="https://linkedin.com/company/advance-auto-movers">
                      <img
                        src={linkedin}
                        alt="Linkedin"
                        style={{ width: '1em', height: '1em' }}
                      />
                    </a>
                    <a title="twitter" href="https://twitter.com/adv_auto_movers">
                      <img
                        className="fas fa-lg"
                        src={twitter}
                        alt="Twitter"
                        style={{ width: '1em', height: '1em' }}
                      />
                    </a>
                  </div>
                </div>

              </div>

              <div className="column is-8">
                <h4>
                  You can use the form below to briefly describe what you are inquiring about.
                </h4>

                <form
                  name="contact"
                  method="post"
                  action="/contact/thanks/"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={this.handleSubmit}
                >
                  <fieldset>
                  {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                  <input type="hidden" name="form-name" value="contact" />
                  <div hidden>
                    <label>
                      Don’t fill this out:{' '}
                      <input name="bot-field" onChange={this.handleChange} />
                    </label>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor={'name'}>
                      Name
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={'text'}
                        name={'name'}
                        onChange={this.handleChange}
                        id={'name'}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor={'email'}>
                      Email
                    </label>
                    <div className="control">
                      <input
                        className="input"
                        type={'email'}
                        name={'email'}
                        onChange={this.handleChange}
                        id={'email'}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" htmlFor={'message'}>
                      Message
                    </label>
                    <div className="control">
                      <textarea
                        className="textarea"
                        name={'message'}
                        onChange={this.handleChange}
                        id={'message'}
                        required={true}
                      />
                    </div>
                  </div>

                  <div className="field">
                    <button className="button is-link" type="submit">
                      Send
                    </button>
                  </div>
                  </fieldset>
                </form>
              </div>
              
            </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
